<template>
<b-container title="Connexion"  body-class="custom-modal-body h-100">
    <b-img v-if="logoUrl !=null"
        thumbnail 
        fluid 
        :src="logoUrl" 
        alt="logo"
        class="mx-auto d-flex mt-3"
        style="height: 250px; width: 250px;"
    ></b-img>
    <b-form @submit.prevent="login" class=" d-flex flex-column mx-auto" style="width: 35% !important; margin-top: 15px;">
    <b-form-group label="Login" class="text-second">
        <b-form-input v-model="username" type="text" required></b-form-input>
    </b-form-group>
    <b-form-group label="Mot de passe" class="text-second">
        <b-input-group>
        <b-form-input v-model="password" :type="showPassword ? 'text' : 'password'" required @keyup.enter="login"></b-form-input>
        <b-input-group-append>
            <b-button @click="showPassword = !showPassword" variant="outline-primary">
            <b-icon :icon="showPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon>
            </b-button>
        </b-input-group-append>
        </b-input-group>
    </b-form-group>
    <small class="form-text text-danger p-2 " v-if="errorLogin">Nom d'utilisateur ou mot de passe incorrect</small>
    <b-button variant="primary" type="submit"> <b-icon icon="box-arrow-in-right"></b-icon> Se connecter</b-button>
    <!-- <b-button @click="submitSignup()" variant="primary" class="ml-4"> <b-icon icon="pen"></b-icon>Créer un compte</b-button> -->
    </b-form>
</b-container>
</template>

<script>
import BaseComponent from '../../BaseComponent';
import Component from 'vue-class-component';

@Component
export default class LoginUserPanel extends BaseComponent {
    logoUrl = null;

    username = '';

    password = '';

    showPassword = false;

    errorLogin = false;

    mounted(){
        const isConnected = this.contextService.isConnect()
        if(this.contextService.configuration && this.contextService.configuration.standalone_options 
        && this.contextService.configuration.standalone_options.login_logo
        && this.contextService.configuration.standalone_options.login_logo.length>0){
            this.logoUrl = this.contextService.configuration.standalone_options.login_logo;
        }
        if(isConnected){
            if(this.contextService.mapDefault === "" && this.contextService.mapNb > 0){
                this.$router.push({ path: `/maps` });
            }else if(this.contextService.mapDefault === "" && this.contextService.mapNb <= 0){
                this.$router.push({ path: `/map/new` });
            }else if(this.contextService.mapDefault > 0){
                this.$router.push({ path: `/map/${this.contextService.mapDefault}` });
            }
        }
    }
    submitSignup() {
        this.$router.push({ path: '/users/signup' });
    }

    submitlogin() {
        this.contextService.login(this.username, this.password).then((result) => {
            if (result.data.authenticated) {
                this.$bvToast.toast(`${result.data.user.name} connecté`, {
                    title: 'Connexion réussie',
                    variant: 'success',
                    autoHideDelay: 0e3,
                    solid: true,
                });
                this.contextService.mapDefault = result.data.mapDefault
                this.contextService.mapNb = result.data.mapNb
                if(this.contextService.mapDefault === "" && this.contextService.mapNb > 0){
                    this.$router.push({ path: `/maps` });
                }else if(this.contextService.mapDefault === "" && this.contextService.mapNb <= 0){
                    this.$router.push({ path: `/map/new` });
                }else if(this.contextService.mapDefault > 0){
                    this.$router.push({ path: `/map/${this.contextService.mapDefault}` });
                }
                this.errorLogin = false;
            } else {
                this.errorLogin = true;
            }
        });
    }

    login() {
        if (this.username && this.password) {
            this.submitlogin();
        } else {
            // Afficher un message d'erreur ou des indications à l'utilisateur
        }
    }


    isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
}
</script>

<style scoped lang="less">

</style>
