















/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
import Component from 'vue-class-component';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import AlertPanel from '@/components/shared/AlertPanel.vue';
import BaseComponent from '@/components/BaseComponent';
import { EventBus, EVENTS } from '@/services/EventBus';

// eslint-disable-next-line no-use-before-define
@Component<MapList>({
  props: {
  },
  watch: {
    $route(to, from) {
      this.routeChange(to, from);
    },
  },
  components: {
    Header,
    Footer,
    AlertPanel,

  },
})
export default class MapList extends BaseComponent {
  mapKeywordFilter='';

  mounted() {
    this.contextService.cancelEdition();
    if (this.contextService.configLoaded) {
      this.onConfigLoaded(this.contextService.configuration);
    } else {
      EventBus.$on(EVENTS.CONFIG_LOADED, (config:any) => this.onConfigLoaded(config));
    }
    const isConnected = this.contextService.isConnect()
    const forceLogin = this.contextService.forceLogin()
    if(!isConnected && forceLogin){
      this.$router.push({ path: `/login` });
    }
  }

  routeChange(to:any, from:any) {
    console.log(to, from);
    this.onConfigLoaded(this.contextService.configuration);
  }

  getHeaderHeight() {
    if (this.contextService.configuration.customHeaderAndFooter && this.contextService.configuration.customHeaderAndFooter.header_height) {
      return `min-height:${this.contextService.configuration.customHeaderAndFooter.header_height}px`;
    }
    return '';
  }

  onConfigLoaded(config:any) {
    console.log(this.$route);

    const showHeader = this.$route.query.header;

    const mapKeywordFilter:any = this.$route.query.kw;

    if (mapKeywordFilter !== undefined) {
      this.mapKeywordFilter = mapKeywordFilter;
    }

    if (showHeader !== undefined && showHeader === 'false') {
      this.contextService.showHeader = false;
    }

    const showFooter = this.$route.query.footer;
    if (showFooter !== undefined && showFooter === 'false') {
      this.contextService.showFooter = false;
    }
  }
}

